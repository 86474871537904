(function($) {

    /**
     * AnimateScroll
     * Receives the element with the hash and a optional plus for the offset.
     */
    function animateScroll ( hashElement, offsetToTop )
    {
        // Check if the variables exists
        if ( hashElement === undefined ) {
             return false;
         }
        if ( offsetToTop === undefined ) {
             offsetToTop = 0;
        }
        // Check for the path name
        if ( location.pathname.replace(/^\//,'') === hashElement[0].pathname.replace(/^\//,'') && location.hostname === hashElement[0].hostname ) {
            var $target = $( hashElement[0].hash );
                $target = $target.length ? $target : $('[name='+ hashElement[0].hash.slice(1) +']');
            console.log($target.length);
            if ($target.length <= 0) {
                return false;
            }
            // Add the offset to the equation
            offsetToTop = $target.offset().top - offsetToTop;
            $('html, body').animate({ scrollTop: offsetToTop}, 1000, function(){
                $target.focus();
            });
            return false;
        }
    }
    // animate.scroll */

    /**
     * FORM VALID/INVALID METHODS
     * Handles the form elements validations
     *
     * group: (jquery) form-group to be handled
     * valid: (boolean) should be handled has valid or invalid;
     * message: (string) the message to be shown to the user;
     */
    function formValidationThreatment( group, valid, message )
    {
        // With form element we should handle?
        if (group === undefined) {
            console.log('formValidationThreatment: Não foi passada a variável "group"');
            return false;
        }
        // Proper threatment?
        if (valid === undefined) {
            console.log('formValidationThreatment: Não foi passada a variável "valid"');
            return false;
        }
        // Default message is no message!
        if (message === undefined) {
            message = '';
        }
        // Find some elements
        var $control = group.find('.form-control'),
            $helpers = group.find('.form-control-error');
        // Check if is valid or NOT
        if (valid) {
            // Undo 'has-error' prerrogatives
            group.removeClass('has-error');
            $helpers.html('');
            $control.attr('aria-invalid', false);
            $control.attr('aria-labelledby', false);
            // Set 'has-success' threatment
            group.addClass('has-success');
        } else {
            // Is invalid and has no message?
            if (message === '') {
                console.log('formValidationThreatment: É necessário cadastrar uma mensagem caso o retorno seja inválido.');
                return false;
            }
            // Undo 'has-success' prerrogatives
            group.removeClass('has-success');
            // Set 'has-error' threatment
            group.addClass('has-error');
            $helpers.html(message);
            $control.attr('aria-invalid', true);
            $control.attr('aria-labelledby', $helpers.attr('id'));
        }
        return true;
    }
    // form-validation */

    $(document).ready(function() {

        /**
         * FORM DEPENDS
         * When a input depends of another input
         * Checks for :checked or value
         */
        $('input[type="radio"], input[type="checkbox"], select').on('change', function() {
            $value  = $(this).val();

            // Check if is checkbox
            if ( $(this).attr('type') == 'checkbox' && !$(this).prop('checked') ) {
                $value = '';
            }
            // Get the dependencies
            $depnds = '[data-form-depends="'+ this.name +'"]';
            $valued = '[data-form-value="'+ $value +'"]';

            // Run the logic!
            $($depnds).hide()
                .attr('aria-hidden', true)
                .find('[data-form-required]').attr('required', false);
            $($depnds + $valued).show()
                .attr('aria-hidden', false)
                .find('[data-form-required]').attr('required', true);
            // End the logic!
        });
        // form dependencies */

        /**
         * SEARCH FOCUS
         */
        $('#header-search').on('shown.bs.collapse', function(target, e){
            console.log(target);
            console.log(e);
            console.log($(this));
            console.log('teste!');
            $(this).find('input')[0].focus();
        });
        // search-focus

        /**
         * HEADER MOBILE
         */
        $('#header-mobile').on('show.bs.collapse', function() {
            // Stop Scrolling
            $('body').addClass('fixed');
            // Inert value
            document.querySelector('.site-header #header-mobile').inert = false;
        });
        $('#header-mobile').on('shown.bs.collapse', function() {
            // Set focus
            $(this).find('button')[0].focus();
            // Inert value
            document.querySelector('.site-shcuts').inert = true;
            document.querySelector('.site-mainer').inert = true;
            document.querySelector('.site-finish').inert = true;
            document.querySelector('.site-footer').inert = true;
            document.querySelector('.header-navbar').inert = true;
            document.querySelector('.header-navtop').inert = true;
        });
        $('#header-mobile').on('hide.bs.collapse', function() {
            // Start Scrolling
            $('body').removeClass('fixed');
            // Inert value
            document.querySelector('.site-header #header-mobile').inert = true;
        });
        $('#header-mobile').on('hidden.bs.collapse', function() {
            // Inert value
            document.querySelector('.site-shcuts').inert = false;
            document.querySelector('.site-mainer').inert = false;
            document.querySelector('.site-finish').inert = false;
            document.querySelector('.site-footer').inert = false;
            document.querySelector('.header-navbar').inert = false;
            document.querySelector('.header-navtop').inert = false;
            // Set focus
            $('.header-button-mobile').focus();
        });

        /**
         * CONTRAST
         * Remember to add the icon trougth CSS
         */
        $('[href="#contrast"]').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('body').toggleClass('contrast');
        });
        // external-site */

        /**
         * RESPONSIVE IFRAME
         * Make iframes added from WordPress responsive
         */
        $('.entry-content').find( 'iframe, embed, object, [data-ht], video, .video-js' ).each(function(){
            $(this).wrap( '<div class="embed-responsive embed-responsive-16by9"></div>' );
        });
        $('.entry-content').find( '.video-js' ).each(function(){
            $(this).addClass('embed-responsive-item');
        });
        // responsive.iframe

        /*!
         * POP-UP SHARE
         * Open share options on a pop-up window
         * I know it feels like coding in 1999, but
         * it's what most social buttons do anyway.
         */
        $('.entry-share a.sm-share').on('click', function(e)
        {
            e.preventDefault();
            e.stopPropagation();
            var $h = 510,
                $w = 510,
                px = Math.floor( ((screen.availWidth || 1024) - $w) / 2 ),
                py = Math.floor( ((screen.availHeight || 700) - $h) / 2 );
            var popup = window.open( $(this).attr('href'), 'social',
                'width='+$w+',height='+$h+',left='+px+',top='+py+
                ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
            if ( popup ) {
                popup.focus();
                e.preventDefault();
            }
            return !!popup;
        });

        /**
         * EXTERNAL LINKS
         * Remember to add the icon trougth CSS
         */
        $('body').find('a[href*="http"]').each(function() {
            // Don't run if is from the same domain.
            var a = new RegExp('/' + window.location.host + '/');
            if(a.test(this.href) || $(this).hasClass('skip-external')) {
                return true;
            }
            // Add external rel attribute
            $(this).attr('rel', 'external');
            $(this).attr('title', 'Site externo');
            // Check if has text element
            if ($(this).find('.text') && $(this).find('.text').length > 0) {
                $(this).addClass('skip-withtext');
            }
        });
        // external-site */

    }); // document.ready
})(jQuery)